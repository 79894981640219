import React from "react";
import { Route, Redirect } from "react-router-dom"
import {useSigninCheck} from "reactfire";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { status, data: signInCheckResult } = useSigninCheck();

    if (status === 'loading') {
        return <span>loading...</span>;
    }

    return (
        <Route
            {...rest}
            render={props => {
                return signInCheckResult.signedIn === true ? <Component {...props} /> : <Redirect to="/login" />
            }}
        ></Route>
    )
}

export default ProtectedRoute;
