import { Link } from 'react-router-dom'
import { BsPlus, BsGearFill } from 'react-icons/bs';
import { GiBalaclava } from 'react-icons/gi'
import {useSigninCheck} from "reactfire";

const SideBar = () => {

    const { status, data: signInCheckResult } = useSigninCheck();

    if (status === 'loading') {
        return <span>loading...</span>;
    }

    if (signInCheckResult.signedIn === true) {
        return (
            <div className="fixed top-0 left-0 h-screen w-16 flex flex-col
                      bg-white dark:bg-gray-900 shadow-lg">

                <Link to='/'>
                    <SideBarIcon icon={<GiBalaclava size="28" />} text="REBIRTH RIVALS" />
                </Link>
                <Divider />
                <SideBarIcon icon={<BsPlus size="32" />} text="New custom game" />
                <Divider />
                <SideBarIcon icon={<BsGearFill size="22" />} text="Settings" />
            </div>
        );
    }

    return (
        <div className="fixed top-0 left-0 h-screen w-16 flex flex-col
                      bg-white dark:bg-gray-900 shadow-lg">

            <Link to='/'>
                <SideBarIcon icon={<GiBalaclava size="28" />} text="REBIRTH RIVALS" />
            </Link>
        </div>
    );
};

const SideBarIcon = ({ icon, text = 'tooltip 💡' }) => (
  <div className="sidebar-icon group">
    {icon}
    <span class="sidebar-tooltip group-hover:scale-100">
      {text}
    </span>
  </div>
);


const Divider = () => <hr className="sidebar-hr" />;

export default SideBar;
