import LogoutButton from "../LogoutButton";

const Dashboard = () => {
    return (
        <div className='post-content'>
            <h1 className='post-title'>REBIRTH RIVALS</h1>
            <p className='post-text'>
                Keep your eyes peeled for updates here or on my Twitter @_heiszee
            </p>

            <LogoutButton />
        </div>
    );
};

export default Dashboard;
