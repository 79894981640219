import {Switch, Route, useHistory} from "react-router-dom";
import TopNavigation from '../TopNavigation';
import ProtectedRoute from "../ProtectedRoute";
import Login from "../Login";
import Dashboard from "../Dashboard";
import {getAuth, isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";
import {useFirebaseApp} from "reactfire";
import logo from './RebirthRivalsWhite.png'

const ContentContainer = () => {

    const app = useFirebaseApp();
    const firebaseAuth = getAuth(app);
    const history = useHistory()


    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
            email = window.prompt('Please provide your email for confirmation');
        }

        signInWithEmailLink(firebaseAuth, email, window.location.href)
            .then((result) => {
                window.localStorage.removeItem('emailForSignIn');
                history.push('/')
            })
            .catch((error) => {

            });
    }

    return (
        <div className='content-container'>
            <TopNavigation />
            <img
                src={logo}
                alt="Logo"
                className="object-scale-down h-48"
            />
            <Switch>
                <ProtectedRoute exact path="/" component={Dashboard} />
                <Route path="/login" component={Login} />
            </Switch>
        </div>
    );
};

export default ContentContainer;
