import {ErrorMessage, Field, Form, Formik} from "formik";
import {getAuth} from "firebase/auth";
import {useFirebaseApp} from "reactfire";
import {sendSignInLinkToEmail} from "firebase/auth";


const Login = () => {
    const app = useFirebaseApp();
    const firebaseAuth = getAuth(app);

    return (
        <div className='content-list'>

            <div className={'post'}>
                <div className='post-content'>
                    <h1 className='post-title'>REBIRTH RIVALS</h1>
                    <p className='post-text'>
                        We are building a platform to make <b>finding</b>, <b>joining</b> and <b>hosting </b>
                        custom Rebirth Island matches much easier.
                    </p>
                    <p className='post-text'>
                        Grab an account now to claim your profile.
                    </p>
                    <div className="flex flex-col justify-evenly">
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email = 'Required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                return errors;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                const actionCodeSettings = {
                                    url: window.location.origin,
                                    handleCodeInApp: true,
                                };

                                await sendSignInLinkToEmail(firebaseAuth, values.email, actionCodeSettings)
                                    .then(() => {
                                        window.localStorage.setItem('emailForSignIn', values.email);
                                        alert('Click the link in your email to sign in!')
                                    })
                                    .catch((error) => {
                                        alert(JSON.stringify(error))
                                    });
                            }}
                            className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="flex items-center border-b border-teal-500 py-2">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="HeIsZee@rebirthrivals.com"
                                            className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                                        />
                                        <ErrorMessage name="email" component="div" />

                                        <button
                                            className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Login with Magic Link
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <p className='post-text'>
                        Follow me on Twitter for updates: @_heiszee
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login