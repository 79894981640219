import ContentContainer from './components/ContentContainer';
import SideBar from './components/SideBar';

import {BrowserRouter as Router} from "react-router-dom";
import {AnalyticsProvider, AuthProvider, FirestoreProvider, useFirebaseApp} from "reactfire";

import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";

function App() {

    const app = useFirebaseApp();
    const firebaseFirestore = getFirestore(app);
    const firebaseAuth = getAuth(app);
    const firebaseAnalytics = getAnalytics(app)

    return (
        <AuthProvider sdk={firebaseAuth}>
            <FirestoreProvider sdk={firebaseFirestore}>
                <AnalyticsProvider sdk={firebaseAnalytics}>
                    <Router>
                        <div className="flex">
                            <SideBar />
                            <ContentContainer />
                        </div>
                    </Router>
                </AnalyticsProvider>
            </FirestoreProvider>
        </AuthProvider>
    );
}

export default App;