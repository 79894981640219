import {useFirebaseApp} from "reactfire";
import {getAuth, signOut} from "firebase/auth";

const LogoutButton = () => {

    const app = useFirebaseApp();
    const firebaseAuth = getAuth(app);

    async function handleLogout() {
        try {
            await signOut(firebaseAuth)
        } catch {

        }
    }

    return (
        <button
            className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
            onClick={handleLogout}
        >
            Logout
        </button>
    )
}

export default LogoutButton