import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import {FirebaseAppProvider} from "reactfire";

const root = document.getElementById('root');

fetch('/__/firebase/init.json').then(async (response) => {
    const firebaseConfig = await response.json()

    render(
        <StrictMode>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <App />
            </FirebaseAppProvider>
        </StrictMode>,
        root,
    );
})
